import { useEffect, useState } from "react"
import numeral from 'numeral'
import Api from "../Api"

import DaftarBarang from "../components/DaftarBarang"
import { useParams } from 'react-router-dom'

import { Divider, Icon, Button } from "@material-ui/core"
import { Helmet } from "react-helmet"

const copyLink = (link) => {
    var textArea = document.createElement("textarea");
    textArea.value = link;
    textArea.style.position="fixed";  //avoid scrolling to bottom
    document.body.appendChild(textArea);
    textArea.focus();
    textArea.select();

    try {
        document.execCommand('copy');
        // var successful = document.execCommand('copy');
        // var msg = successful ? 'successful' : 'unsuccessful';
    } catch (err) {
    }

    document.body.removeChild(textArea)     
}

const loadScript = (src) => {
    return new Promise((resolve, reject)=>{
        let script = document.createElement('script')
        script.src=src
        script.async=false
        script.onload=() => {
            resolve('ok')
        }
        document.head.appendChild(script)
    })
}

const loadCss = (href) => {
    let link = document.createElement('link')
    link.href=href
    link.type = 'text/css'
    link.rel = 'stylesheet'
    document.head.appendChild(link)
}

const RenderStok = (prop)=>{
    let b = prop.barang
    if(Api.isNeedLogin()) {
        if(b.stok < 10 ) {
            return (
                <span className='text-merah2'>Ready [Stok kurang dari 10{b.satuan}]<span className='text-hijau fa fa-check'/></span>
            )
        }
        if(b.stok < 20) {
            return <span className='text-orange'>Ready [Stok kurang dari 20{b.satuan}] <span className='text-hijau fa fa-check'/></span>
        }
        return <span className='text-hijau'>Ready [Stok lebih dari 20{b.satuan}]<span className='text-hijau fa fa-check'/></span>
    } else {
        if(b.stok > 0) {
            return <span className='text-hijau'>Ready <span className='text-hijau fa fa-check'/></span>
        } else {
            return <span className='text-merah2'>Kosong</span>
        }
    }
}

const DetailBarangView = () => {
    const {id} = useParams()

    const [barang, setBarang] = useState({})
    const [daftarFoto, setDaftarFoto] = useState([])
    const [daftarHargaMember, setDaftarHargaMember] = useState([])
    const [daftarBarangLain, setDaftarBarangLain] = useState([])
        
    const fetchData = async() => {
        let api = await Api.getBarang(id)
        if(api.ok) {
            let data = api.data
            if(data.barang) {
                setBarang(api.data.barang)
                setDaftarFoto(api.data.daftarFoto)
                setDaftarHargaMember(api.data.daftarHargaMember)
                setDaftarBarangLain(api.data.daftarBarang)
            }
        } else {
            alert('server error')
        }
    }

    useEffect(()=>{
        (async ()=>{
            await fetchData()
            await loadCss('/vendor/lightslider/css/lightslider.min.css')
            await loadScript('/vendor/lightslider/js/lightslider.min.js')
            await loadCss('/vendor/lightgallery/css/lightgallery.min.css')
            await loadScript('/vendor/lightgallery/js/lightgallery.min.js')
            await loadScript('/vendor/lightgallery/js/lightgallery.min.js')
            await loadScript('/vendor/agus/detailBarang.js')
        })()
    }, [])

    const handleShare = async () => {
        try {
            copyLink(Api.getToko()?.website_url + "db.php?id=" + id + "&tokoid=" + Api.getTokoId())
            alert("Link share telah dicopy ke clipboard")
        } catch (er) {
            alert(er)
        }

    }

    return (
        <div className='container mx-auto max-w-full lg:max-w-7xl'>
            <Helmet>
                <meta property="og:type" content="website" />
                <meta property="og:url" content={window.location.href} />
                <meta property="og:title" content={barang.nama} />
                <meta property="og:description" content={barang.deskripsiweb} />
                <meta property="og:image" content={(Api.baseUrlThumb2 + barang.fotoid)} />

                <meta property="twitter:card" content="summary_large_image" />
                <meta property="twitter:url" content={window.location.href} />
                <meta property="twitter:title" content={barang.nama} />
                <meta property="twitter:description" content={barang.deskripsiweb} />
                <meta property="twitter:image" content={(Api.baseUrlThumb2 + barang.fotoid)} />
            </Helmet>
            <div className='my-3' />
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols2 xl:grid-cols-2'>
                <div>
                    <div className='rounded-lg p-1'>
                        <div className='text-center text-lg'>
                            {barang.nama}
                        </div>
                        <hr className='my-2' />
                        <div className='text-blue-900 font-bold uppercase'>
                            SKU: {barang.sku}
                        </div>
                        <div>
                            Stok: <RenderStok barang={barang}/>
                        </div>
                        <div className='text-blue-900 mb-2'>
                            Rp <span>{numeral(barang.harga).format("0,0")}</span> / {barang.satuan}
                        </div>
                        <div dangerouslySetInnerHTML={{ __html: barang.keterangan }} />
                        <div>
                            {
                                Api.isNeedLogin() && daftarHargaMember.map((x, index)=>(
                                    <div key={index}>
                                        *beli minimal {x.minqty}{barang.satuan} <span className="text-blue-900"> Rp {numeral(x.hargajual).format("0,0")} / {barang.satuan} </span>
                                    </div>
                                ))
                            }
                        </div>
                        <div className="mt-2">
                            <Button variant="contained" onClick={handleShare} startIcon={<Icon className="fa fa-share" />}>
                                Share Link
                            </Button>
                        </div>
                    </div>
                    <hr className='my-3' />
                </div>
            </div>
            <div className='grid grid-cols-1 md:grid-cols-2 lg:grid-cols2 xl:grid-cols-2'>
                <div className='image-gallery m-2'>
                    <ul id='imageGallery'>
                        {
                            daftarFoto.map(x=>(
                                <li key={x.id} data-thumb={(Api.baseUrlThumb2 + x.id)} data-src={(Api.baseUrlFoto + x.id)}>
                                    <img src={(Api.baseUrlThumb2 + x.id)} className='w-full mx-auto' alt='foto' style={{maxWidth: 425, maxHeight: 425}}/>
                                </li>
                            ))
                        }
                    </ul>
                </div>
                <div style={{minHeight: 80}}>
                    <div>
                        {
                            ((barang.deskripsiweb || '').length > 0) && (
                                <div className='p-2'>
                                    <div className='font-bold'>Deskripsi</div>
                                    <div dangerouslySetInnerHTML={{ __html: barang.deskripsiweb }} />
                                </div>
                            )
                        }
                    </div>
                </div>
            </div>
            <hr className='my-3' />
            <div className='daftar-barang-lain mx-2'>
                <DaftarBarang judul='PRODUK LAINNYA' daftar={daftarBarangLain}/>
            </div>
            <Divider />
            <div className='my-3' />
        </div>
    )
}

export default DetailBarangView