const Slogan = (props) => {
    return (
        <div>
            <div className='flex items-center content-center gap-2'>
                <div className='text-center flex-grow-0 flex-shrink-0'>
                    <div style={{ width: 70 }}>
                        <img style={{ maxWidth: 70, maxHeight: 70 }} src={props.img} alt='test' className="mx-auto" />
                    </div>
                </div>
                <div className='slogan-text flex-grow'>
                    <div className='font-bold'>
                        {props.title}
                    </div>
                    <div className="text-sm">
                        {props.text}
                    </div>
                </div>
            </div>
        </div>
    )
}

export default Slogan