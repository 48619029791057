import { Swiper, SwiperSlide } from 'swiper/react'
import 'swiper/swiper.scss'

import Api from '../Api'
import { useEffect, useState } from 'react'
import DaftarBarang from '../components/DaftarBarang'
import { Backdrop, CircularProgress, Divider, makeStyles } from '@material-ui/core'

import KepalaKategori from '../components/KepalaKategori'
import Slogan from '../components/Slogan'

import slogan1 from '../aset/slogan1.png'
import slogan2 from '../aset/slogan2.png'
import slogan3 from '../aset/slogan3.png'

const useStyles = makeStyles((theme) => ({
    backdrop: {
        zIndex: theme.zIndex.drawer + 1,
        color: '#fff',
    },
}));


const HomeView = () => {
    const classes = useStyles();
    const [busy, setIsBusy] = useState(false);
    const [daftarBanner1, setDaftarBanner1] = useState([])
    const [daftarBanner2, setDaftarBanner2] = useState([])
    const [daftarBarangBaru, setDaftarBarangBaru] = useState([])
    const [daftarRestok, setDaftarRestok] = useState([])
    const [daftarKategori2, setDaftarKategori2] = useState([])
    const [gudang, setGudang] = useState(-1)
    const [daftarGudang, setDaftarGudang] = useState([])

    const handleGudangChange = (e) => {
        let s = e.target.value.toLowerCase()
        Api.setGudangId(s)
        setGudang(s)
        fetchData()
    }

    const judul1 = () => {
        return (
            <>
                <i className="fa fa-bolt mr-2" />
                Produk Terbaru
                <i className="fa fa-bolt ml-2" />
            </>
        )
    }

    const judul2 = () => {
        return (
            <>
                <i className="fa fa-sync-alt mr-2" />
                Restok Update Saat Ini
            </>
        )
    }

    const RenderDaftarBarangBaru = () => {
        if (daftarBarangBaru.length > 0) {
            return <DaftarBarang daftar={daftarBarangBaru} judul={judul1()} />
        }
        return <></>
    }

    const RenderPilihanGudang = () => {
        return (
            <div className="mx-auto">
                <div className="relative inline-block w-full text-gray-700">
                    <select value={gudang} onChange={handleGudangChange} className="text-center w-full h-10 pl-3 pr-6 text-base placeholder-gray-600 border rounded-lg appearance-none focus:shadow-outline" placeholder="Regular input">
                        {
                            daftarGudang.map(x => {
                                return <option key={x.id} value={x.id}>{x.nama}</option>
                            })
                        }
                    </select>
                    <div className="absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <svg className="w-4 h-4 fill-current" viewBox="0 0 20 20"><path d="M5.293 7.293a1 1 0 011.414 0L10 10.586l3.293-3.293a1 1 0 111.414 1.414l-4 4a1 1 0 01-1.414 0l-4-4a1 1 0 010-1.414z" clip-rule="evenodd" fill-rule="evenodd"></path></svg>
                    </div>
                </div>
            </div>
        )
    }

    const RenderDaftarBarangRestok = () => {
        if (daftarRestok.length > 0) {
            return <DaftarBarang daftar={daftarRestok} judul={judul2()} />
        }
        return <></>
    }

    const fetchData = async () => {
        setIsBusy(true)
        let api = await Api.getHomeData(Api.getGudangId())
        if (api.ok) {
            let daf1 = api.data.daftarBanner.filter(x => x.kategori.toLowerCase() === 'banner1')
            let daf2 = api.data.daftarBanner.filter(x => x.kategori.toLowerCase() === 'banner2')
            setDaftarBanner1(daf1)
            setDaftarBanner2(daf2)
            setDaftarBarangBaru(api.data.daftarBarangBaru)
            setDaftarRestok(api.data.daftarRestok)
            setDaftarKategori2(api.data.daftarKategori2)
        } else {
            alert('terjadi kesalahan')
        }
        setIsBusy(false)
    }

    useEffect(() => {
        async function test() {
            let dg = await Api.getDaftarGudang(Api.getTokoId())
            setDaftarGudang(dg)
            if (dg.length > 0) {
                let gudang_id = dg[0].id
                Api.setGudangId(gudang_id)
                setGudang(gudang_id)
            }
            fetchData()
        }
        test()
    }, [])

    const banner2Breakpoint = {
        500: {
            slidesPerView: 2
        },

    }

    return (
        <div className='container mx-auto max-w-full lg:max-w-7xl'>
            <Backdrop className={classes.backdrop} open={busy}>
                <CircularProgress color="inherit" />
            </Backdrop>
            <div className='my-3' />
            <div className='banner1'>
                <Swiper slidesPerView={1} observer={true}>
                    {
                        daftarBanner1.map((el, idx) => {
                            return (
                                <SwiperSlide key={el.id}>
                                    <img src={Api.getBaseUrl() + 'fotobanner/' + el.id} className='w-full' alt='test' />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            <div className='my-3' />
            <div className='banner2'>
                <Swiper slidesPerView={1} breakpoints={banner2Breakpoint} observer={true}>
                    {
                        daftarBanner2.map((el, idx) => {
                            return (
                                <SwiperSlide key={el.id}>
                                    <img src={Api.getBaseUrl() + 'fotobanner/' + el.id} className='w-full' alt='test' />
                                </SwiperSlide>
                            )
                        })
                    }
                </Swiper>
            </div>
            <Divider />
            <div className='my-3' />
            <div className="mx-auto"  style={{ maxWidth: 325 }}>
                <RenderPilihanGudang />
            </div>

            <div className='my-3' />
            <div className='mx-2'>
                <RenderDaftarBarangBaru />
            </div>
            <Divider />
            <div className='my-3' />
            <div className='mx-2'>
                <RenderDaftarBarangRestok />
            </div>
            <Divider />
            <div className='my-3' />
            <div className="mx-2">
                {
                    daftarKategori2.map((x, idx) => {
                        return (
                            <KepalaKategori key={x.id} item={x} />
                        )
                    })
                }
            </div>
            <div className='my-3' />
            <Divider />
            <div className='daftar-slogan grid grid-cols-1 md:grid-cols-3 lg:grid-cols-3 2xl:grid-cols-3'>
                <Slogan img={slogan1} title='SUPPLIER TERPERCAYA' text='Produk yang kami jual merupakan produk yang laku dari permintaan pasar' />
                <Slogan img={slogan2} title='HARGA TERMURAH' text='Kami fokus memberikan harga termurah untuk perkembangan usaha Anda' />
                <Slogan img={slogan3} title='PELAYANAN MUDAH DAN CEPAT' text='Hubungi kami dan barang diproses saat itu juga' />
            </div>
            <Divider />
            <div className='my-3' />
        </div>
    )
}

export default HomeView